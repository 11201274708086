
.contenfulValidationErrorMessage{
    display: flex;
    align-items: center;
    background: #ff5151;
    border-radius: 20px;
    padding: 12px;
    font-weight: bold;
    font-size: large;
}

.contenfulValidationErrorMessage svg{
font-size: 26px;
margin-right: 12px;
}